{
	"accessibility": {},
	"images": {
		"logo": "/assets/images/john-wieland/john-wieland-logo.svg",
		"favicon":"/assets/images/john-wieland/john-wieland-favicon.png"
	},
	"designPreviewImageUrls" : [
		"/assets/images/john-wieland/1st-john-wieland-dp-image.jpg",
		"/assets/images/john-wieland/2nd-john-wieland-dp-image.jpg",
		"/assets/images/john-wieland/3rd-john-wieland-dp-image.jpg",
		"/assets/images/john-wieland/4th-john-wieland-dp-image.jpg"
	],
	"styles": {
		"font-regular": "Palatino-Linotype",
		"brand-primary": "#002855",
		"brand-secondary": "#002855",
		"brand-tertiary": "#E6EAEE",
		"brand-tertiary-light": "#EFF1F4",
		"action-clickable": "#002855",
		"action-hover": "#001022",
		"action-clickable-light": "#EFF1F4",
		"action-focus": "#000000"
	}
}