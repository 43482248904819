{
	"accessibility": {},
	"images": {
		"logo": "/assets/images/divosta/divosta-logo.svg",
		"favicon":"/assets/images/divosta/divosta-favicon.png"
	},
	"designPreviewImageUrls" : [
		"/assets/images/divosta/1st-divosta-dp-image.jpg",
		"/assets/images/divosta/2nd-divosta-dp-image.jpg",
		"/assets/images/divosta/3rd-divosta-dp-image.jpg",
		"/assets/images/divosta/4th-divosta-dp-image.jpg"
	],
	"styles": {
		"font-regular": "Stag-Sans",
		"brand-primary": "#0B7699",
		"brand-secondary": "#1E677D",
		"brand-tertiary": "#E7F1F5",
		"brand-tertiary-light": "#F4F7F9",
		"action-clickable": "#1E677D",
		"action-hover": "#144554",
		"action-clickable-light": "#F3F8FA",
		"action-focus": "#000102"
	}
}