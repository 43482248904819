{
	"accessibility": {
		"primary": {
			"primary": {
				"aa": false,
				"aaa": false
			},
			"secondary": {
				"aa": false,
				"aaa": false
			},
			"tertiary": {
				"aa": true,
				"aaa": true
			},
			"tertiary-light": {
				"aa": true,
				"aaa": true
			},
			"white": {
				"aa": true,
				"aaa": true
			},
			"gray-05": {
				"aa": true,
				"aaa": true
			},
			"gray-10": {
				"aa": true,
				"aaa": true
			},
			"gray-20": {
				"aa": true,
				"aaa": true
			},
			"gray-30": {
				"aa": true,
				"aaa": true
			},
			"gray-40": {
				"aa": true,
				"aaa": true
			},
			"gray-50": {
				"aa": true,
				"aaa": false
			},
			"gray-60": {
				"aa": false,
				"aaa": false
			},
			"gray-70": {
				"aa": false,
				"aaa": false
			},
			"gray-80": {
				"aa": false,
				"aaa": false
			},
			"gray-90": {
				"aa": false,
				"aaa": false
			},
			"black": {
				"aa": false,
				"aaa": false
			}
		},
		"secondary": {
			"primary": {
				"aa": false,
				"aaa": false
			},
			"secondary": {
				"aa": false,
				"aaa": false
			},
			"tertiary": {
				"aa": true,
				"aaa": false
			},
			"tertiary-light": {
				"aa": true,
				"aaa": false
			},
			"white": {
				"aa": true,
				"aaa": false
			},
			"gray-05": {
				"aa": true,
				"aaa": false
			},
			"gray-10": {
				"aa": true,
				"aaa": false
			},
			"gray-20": {
				"aa": true,
				"aaa": false
			},
			"gray-30": {
				"aa": false,
				"aaa": false
			},
			"gray-40": {
				"aa": false,
				"aaa": false
			},
			"gray-50": {
				"aa": false,
				"aaa": false
			},
			"gray-60": {
				"aa": false,
				"aaa": false
			},
			"gray-70": {
				"aa": false,
				"aaa": false
			},
			"gray-80": {
				"aa": false,
				"aaa": false
			},
			"gray-90": {
				"aa": false,
				"aaa": false
			},
			"black": {
				"aa": false,
				"aaa": false
			}
		}
	},
	"images": {
		"logo": "/assets/images/del-webb/del-webb-logo.svg",
		"favicon":"/assets/images/del-webb/del-webb-favicon.png"
	},
	"designPreviewImageUrls" : [
		"/assets/images/del-webb/1st-del-webb-dp-image.jpg",
		"/assets/images/del-webb/2nd-del-webb-dp-image.jpg",
		"/assets/images/del-webb/3rd-del-webb-dp-image.jpg",
		"/assets/images/del-webb/4th-del-webb-dp-image.jpg"
	],
	"styles": {
		"font-regular": "museo-sans",
		"brand-primary": "#41B9DB",
		"brand-secondary": "#0273A6",
		"brand-tertiary": "#EFF5F8",
		"brand-tertiary-light": "#EFF5F8",
		"brand-accent-gold": "#F2C75C",
		"action-clickable": "#0273A6",
		"action-hover": "#015074",
		"action-clickable-light": "#F2F8FB",
		"action-focus": "#000A0F"
	}
}