{
	"accessibility": {
		"primary": {
			"primary": {
				"aa": false,
				"aaa": false
			},
			"secondary": {
				"aa": false,
				"aaa": false
			},
			"tertiary": {
				"aa": true,
				"aaa": false
			},
			"tertiary-light": {
				"aa": true,
				"aaa": false
			},
			"white": {
				"aa": true,
				"aaa": true
			},
			"gray-05": {
				"aa": true,
				"aaa": true
			},
			"gray-10": {
				"aa": true,
				"aaa": true
			},
			"gray-20": {
				"aa": false,
				"aaa": false
			},
			"gray-30": {
				"aa": false,
				"aaa": false
			},
			"gray-40": {
				"aa": false,
				"aaa": false
			},
			"gray-50": {
				"aa": false,
				"aaa": false
			},
			"gray-60": {
				"aa": false,
				"aaa": false
			},
			"gray-70": {
				"aa": false,
				"aaa": false
			},
			"gray-80": {
				"aa": false,
				"aaa": false
			},
			"gray-90": {
				"aa": false,
				"aaa": false
			},
			"black": {
				"aa": false,
				"aaa": false
			}
		}
	},
	"images": {
		"logo": "/assets/images/centex/centex-logo.svg",
		"favicon":"/assets/images/centex/centex-favicon.png"
	},
	"designPreviewImageUrls" : [
		"/assets/images/centex/1st-centex-dp-image-ctx-kitchen.jpg",
		"/assets/images/centex/2nd-centex-dp-image-ctx-dark-wood.jpg",
		"/assets/images/centex/3rd-centex-dp-image-ctx-kitchen-living.jpg",
		"/assets/images/centex/4th-centex-dp-image-ctx-faucet.jpg"
	],
	"styles": {
		"font-regular": "Apercu",
		"brand-primary": "#C73629",
		"brand-secondary": "#343434",
		"brand-tertiary": "#F4F2EC",
		"brand-tertiary-light": "#F7F6F2",
		"action-clickable": "#257FA2",
		"action-hover": "#1C5E78",
		"action-clickable-light": "#F4F9FA",
		"action-focus": "#0F3F57"
	}
}