{
	"accessibility": {
		"primary": {
			"primary": {
				"aa": false,
				"aaa": false
			},
			"secondary": {
				"aa": false,
				"aaa": false
			},
			"tertiary": {
				"aa": true,
				"aaa": true
			},
			"tertiary-light": {
				"aa": true,
				"aaa": true
			},
			"white": {
				"aa": true,
				"aaa": true
			},
			"gray-05": {
				"aa": true,
				"aaa": true
			},
			"gray-10": {
				"aa": true,
				"aaa": true
			},
			"gray-20": {
				"aa": true,
				"aaa": true
			},
			"gray-30": {
				"aa": true,
				"aaa": true
			},
			"gray-40": {
				"aa": true,
				"aaa": true
			},
			"gray-50": {
				"aa": true,
				"aaa": false
			},
			"gray-60": {
				"aa": false,
				"aaa": false
			},
			"gray-70": {
				"aa": false,
				"aaa": false
			},
			"gray-80": {
				"aa": false,
				"aaa": false
			},
			"gray-90": {
				"aa": false,
				"aaa": false
			},
			"black": {
				"aa": false,
				"aaa": false
			}
		},
		"secondary": {
			"primary": {
				"aa": false,
				"aaa": false
			},
			"secondary": {
				"aa": false,
				"aaa": false
			},
			"tertiary": {
				"aa": true,
				"aaa": false
			},
			"tertiary-light": {
				"aa": true,
				"aaa": false
			},
			"white": {
				"aa": true,
				"aaa": false
			},
			"gray-05": {
				"aa": true,
				"aaa": false
			},
			"gray-10": {
				"aa": true,
				"aaa": false
			},
			"gray-20": {
				"aa": true,
				"aaa": false
			},
			"gray-30": {
				"aa": false,
				"aaa": false
			},
			"gray-40": {
				"aa": false,
				"aaa": false
			},
			"gray-50": {
				"aa": false,
				"aaa": false
			},
			"gray-60": {
				"aa": false,
				"aaa": false
			},
			"gray-70": {
				"aa": false,
				"aaa": false
			},
			"gray-80": {
				"aa": false,
				"aaa": false
			},
			"gray-90": {
				"aa": false,
				"aaa": false
			},
			"black": {
				"aa": false,
				"aaa": false
			}
		}
	},
	"images": {
		"logo": "/assets/images/pulte/pulte-logo.svg",
		"favicon":"/assets/images/pulte/pulte-favicon.png"
	},
	"designPreviewImageUrls" : [
		"/assets/images/pulte/1st-pulte-dp-image.jpg",
		"/assets/images/pulte/2nd-pulte-dp-image.jpg",
		"/assets/images/pulte/3rd-pulte-dp-image.jpg",
		"/assets/images/pulte/4th-pulte-dp-image.jpg"
	],
	"styles": {
		"font-regular": "Lineto-Brown-Pro",
		"brand-primary": "#003048",
		"brand-secondary": "#2A72A1",
		"brand-tertiary": "#E9F1F7",
		"brand-tertiary-light": "#F2F5F6",
		"action-clickable": "#1B75A1",
		"action-hover": "#145575",
		"action-clickable-light": "#F4F8FA",
		"action-focus": "#0F3F57"
	}
}