{
	"accessibility": {},
	"images": {
		"logo": "/assets/images/american-west/american-west-logo.svg",
		"favicon":"/assets/images/american-west/american-west-favicon.png"
	},
	"designPreviewImageUrls" : [
		"/assets/images/american-west/1st-american-west-dp-image.jpg",
		"/assets/images/american-west/2nd-american-west-dp-image.jpg",
		"/assets/images/american-west/3rd-american-west-dp-image.jpg",
		"/assets/images/american-west/4th-american-west-dp-image.jpg"
	],
	"styles": {
		"font-regular": "Optima-Linotype",
		"brand-primary": "#255C7B",
		"brand-secondary": "#255C7B",
		"brand-tertiary": "#F3F3F3",
		"brand-tertiary-light": "#EAEAEA",
		"action-clickable": "#255C7B",
		"action-hover": "#193F54",
		"action-clickable-light": "#F4F7F8",
		"action-focus": "#020405"
	}
}